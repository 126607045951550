import { AWS_CONFIG as config } from './config-service';

export class CognitoService {
  constructor() {
    this.idToken = localStorage.getItem('id-token');
  }

  login() {
    window.location.href = `https://${config.cognitoDomain}.auth.${config.region}.amazoncognito.com/login?client_id=${config.cognitoClientId}&response_type=code&scope=openid&redirect_uri=${config.appUrl}`;
  }

  async retrieveIdToken(code) {
    const params = new URLSearchParams();

    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('client_id', config.cognitoClientId);
    params.append('redirect_uri', config.appUrl);

    const response = await fetch(`https://${config.cognitoDomain}.auth.${config.region}.amazoncognito.com/oauth2/token`, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: params
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    if (data.id_token) {
      this.idToken = data.id_token;
      localStorage.setItem('id-token', this.idToken);
    }
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  logout() {
    localStorage.removeItem('id-token');
    window.location.href = `https://${config.cognitoDomain}.auth.${config.region}.amazoncognito.com/logout?logout_uri=${config.appUrl}&client_id=${config.cognitoClientId}`;
  }

  getIdToken() {
    return this.idToken;
  }

  isLoggedIn() {
    return this.idToken != null;
  }

  getAuthUserDetails() {
    let userDetails = {
      authorized: false
    }

    if (this.isLoggedIn()) {
      const token = this.parseJwt(this.idToken);

      userDetails.authorized = true;
      userDetails.userId = token.sub;
      userDetails.email = token.email;
    }

    return userDetails;
  }

  getUserEmail() {
    if (this.isLoggedIn()) {
      return this.parseJwt(this.idToken).email;
    }
    else {
      return '';
    }
  }
}

export default new CognitoService();
