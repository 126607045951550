import Todo from "./Todo"

const Todos = ({ todos, onDelete, onToggle }) => {
    return (
        <section>
            <div className="container">
                {/* header */}
                <div className="grid grid-todo todo-header">
                    <div></div>
                    <div>Title</div>
                    <div>When</div>
                    <div></div>
                </div>
                {/* rows */}
                { todos.length > 0 ? todos.map((todo) => ( <Todo key={todo.id} todo={todo} onDelete={onDelete} onToggle={onToggle} /> )) : <div className="grid grid-todo"><div></div><div>No todos to show</div></div>}
            </div>
        </section>
    )
}


// const Todos = ({ todos, onDelete, onToggle }) => {
//     return (
//         <>
//             { todos.map((todo) => ( <Todo key={todo.id} todo={todo} onDelete={onDelete} onToggle={onToggle} /> ))}
//         </>
//     )
// }

export default Todos
