import { useState } from "react";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const AddTodo = ( {onAdd, user} ) => {
    // { userId: 'A1000000-0000-0000-0000-000000000000', id: '00000000-0000-0000-0000-000000000001', title: '0900 Work', when: new Date(2022, 8, 24, 9).getTime() / 1000, completed: true }
    const [title, setTitle] = useState('');
    const [whenDate, setWhenDate] = useState('');
    const [whenTime, setWhenTime] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();

        if (!title) {
            alert('Please add a todo')
            return
        }

        if (!whenDate) {
            alert('Please add a date')
            return
        }

        if (!whenTime) {
            alert('Please add a time')
            return
        }

        let when = moment(whenDate + 'T' + whenTime).toISOString();

        onAdd({ userId: user.userId, id: uuidv4(), title, when });

        setTitle('');
        setWhenDate('');
        setWhenTime('');
    }

    return (
        <section>
            <div className="container">
                <form className='add-todo-form grid grid-todo' onSubmit={onSubmit}>
                    <div></div>
                    <input type="text" placeholder='Add title' 
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <div>
                        <input type="date" placeholder='Add date' 
                            value={whenDate}
                            onChange={(e) => setWhenDate(e.target.value )}
                        />
                        <input type="time" placeholder='Add time' 
                            value={whenTime}
                            onChange={(e) => setWhenTime(e.target.value )}
                        />
                    </div>
                    <input type="submit" value='Add' className='btn' />
                </form>
            </div>
        </section>
    )


    // return (
    //     <section>
    //         <div className="container">
    //             <form className='add-form' onSubmit={onSubmit}>
    //                 <div className="form-control">
    //                     <label>Todo</label>
    //                     <input type="text" placeholder='Add title' 
    //                         value={title}
    //                         onChange={(e) => setTitle(e.target.value)}
    //                     />
    //                 </div>
    //                 <div className="form-control">
    //                     <label>Date</label>
    //                     <input type="date" placeholder='Add datw' 
    //                         value={whenDate}
    //                         onChange={(e) => setWhenDate(e.target.value )}
    //                     />
    //                 </div>
    //                 <div className="form-control">
    //                     <label>Time</label>
    //                     <input type="time" placeholder='Add time' 
    //                         value={whenTime}
    //                         onChange={(e) => setWhenTime(e.target.value )}
    //                     />
    //                 </div>

    //                 <input type="submit" value='Add Todo' className='btn btn-block' />
    //             </form>
    //         </div>
    //     </section>
    // )
}

export default AddTodo
