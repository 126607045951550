import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Navigation = ({ title, user, logout, login }) => {
    // const auth = useContext(AuthContext)
    // const [user, setUser] = useState({ authorized: false })

    useEffect(() => {
        // auth.getUser().then(u => {
        //     setUser({ email: u.profile.email, name: u.profile.name, authorized: true });
        // }).catch(e => {
        //     console.log("User is not logged in.");
        // });
    }, [])

    const loginClick = () => {
        login();
        return false;
    }

    const logoutClick = () => {
        logout();
        // auth.signinRedirect();
        return false;
    }


    return (
        <div className="navbar">
            <div className="container flex">
                <h1 className="logo">{title}</h1>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        { user.authorized ? 
                            <>
                                <li>{user.email}</li> 
                                <li><a href="#" onClick={logoutClick}>Sign out</a></li>
                            </>
                            : 
                            <>
                                {/* <li><input type="button" value="Sign in" onClick={logout} /></li> */}
                                <li><a href="#" onClick={loginClick}>Sign in</a></li>
                            </>
                        }
                    </ul>
                </nav>
            </div>
        </div>
    )
}

Navigation.defaultProps = {
    title: 'CDK Todo'
}

Navigation.propTypes = {
    title: PropTypes.string.isRequired
}

export default Navigation
