import React, { useEffect, useState } from 'react';
import AddTodo from '../AddTodo';
import Todos from '../Todos';

const Home = ( {todos, addTodo, deleteTodo, toggleTodo, user } ) => {
    useEffect(() => {
    }, []);

    const onAdd = (todo) => {
        // console.log(`add todo: ${JSON.stringify(todo)}`);
        addTodo(todo);
    };

    const onDelete = (id) => {
        // console.log(`delete todo: ${id}`);
        deleteTodo(id);
    };

    const onToggle = (todo) => {
        // console.log(`toggle todo: ${id}`);
        toggleTodo(todo);
    };

    return (
        <>
            <AddTodo onAdd={onAdd} user={user} />
            <Todos todos={todos} onDelete={onDelete} onToggle={onToggle} />
        </>
    )

    // return (
    //     <>
    //         {todos.length > 0 ? <Todos todos={todos} onDelete={deleteTodo} onToggle={toggleReminder} /> : 'No todos to show'}
    //     </>
    // )
}

export default Home
