import { Link } from 'react-router-dom'

const About = ( {text} ) => {
    return (
         <section>
            <div className="container">
                <h2>ABOUT</h2>
                <p>Cdk Todo | Version 1.0 | {text}</p>
                <Link to='/'>Go Home</Link>
            </div>
        </section>
    )
}

export default About