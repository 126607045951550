// import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import cognito from './cognito-service';
import todosApi from './todos-service';

import Navigation from './components/layout/Navigation';
import Home from './components/pages/Home';
import About from './components/pages/About';

// import { todosTestData } from './test/todos-test-data';

let cdkTodosAppRunOnce = 0;

const App = () => {
  const [user, setUser] = useState({ authorized: false });
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    if (cdkTodosAppRunOnce++ < 1) {
      init();
      // setUser(cognito.getAuthUserDetails());
      // setTodos(todosTestData);
    }
  }, []);

  // TODO: Move this method to cognito-service
  const init = () => {
    const queryString = window.location.search;
    const urlParameters = new URLSearchParams(queryString);

    if (urlParameters.has('code')) {
        const code = urlParameters.get('code');
        window.history.replaceState(null, null, window.location.pathname);
        console.log('retrieving token');
        cognito.retrieveIdToken(code)
          .then(() => {
            console.log('token received');
            setUser(cognito.getAuthUserDetails());
            todosApi.getTodos().then((todos) => setTodos(todos));
          })
          .catch((error) => {
            console.log(`error retrieving token: ${error} `);
          })
    }
    else {
      if (cognito.getIdToken() != null) {
        const token = cognito.parseJwt(cognito.getIdToken());
        const expires = new Date(token.exp * 1000);
        
        console.log(`token found - expires ${expires}`);

        setUser(cognito.getAuthUserDetails());
        todosApi.getTodos().then((todos) => setTodos(todos));
      }
      else {
        console.log('no token found');
      }
    }
  }

  const login = () => {
    cognito.login();
  };

  const logout = () => {
    cognito.logout();
  };

  const addTodo = (todo) => {
    console.log(`adding todo: ${JSON.stringify(todo)}`);
    todosApi.addTodo(todo)
      .then(() => {
        console.log(`added todo: ${JSON.stringify(todo)}`);
        // add to todo list
        setTodos([...todos, todo]);
      })
      .catch((error) => {
        console.log(`error adding todo: ${error} `);
      })
  };

  const deleteTodo = (id) => {
    console.log(`delete todo: ${id}`);
    todosApi.deleteTodo(id)
      .then(() => {
        console.log(`deleted todo: ${JSON.stringify(id)}`);
        // delete from todo list
        setTodos(todos.filter(todo => todo.id !== id));
      })
      .catch((error) => {
        console.log(`error deleting todo: ${error} `);
      })
  };

  const toggleTodo = (todo) => {
    console.log(`toggle todo: ${JSON.stringify(todo)}`);
    todo.completed = !todo.completed;
    todosApi.updateTodo(todo)
      .then(() => {
        console.log(`toggled todo: ${JSON.stringify(todo)}`);
        // replace in todo list
        setTodos(todos.map(t => t.id !== todo.id ? t : todo));
      })
      .catch((error) => {
        console.log(`error toggling todo: ${error} `);
      })
  };

  return (
    <Router>
      <Navigation title='CDK Todo Dev' user={user} login={login} logout={logout}  />
      <Routes>
          <Route exact={true} path="/" element={<Home todos={todos} addTodo={addTodo} deleteTodo={deleteTodo} toggleTodo={toggleTodo} user={user} />} />
          <Route exact={true} path="/about" element={<About text={'EazyOzy'} />} />
      </Routes>
    </Router>
  );
}

export default App;
