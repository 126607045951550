import { AWS_CONFIG as config } from './config-service';
import cognito from './cognito-service';

export default class TodosService {
    static async getTodos() {
      const response = await fetch(`${config.apiUrl}/todos`, {
            method: 'GET',
            headers: {
              'Authorization': `${cognito.getIdToken()}`
            }
          });

      if (!response.ok) {
        const message = `An error has occured: ${await response.text()}`;
        throw new Error(message);
      }

      const data = await response.json();
      return data.Items;
    };

    static async addTodo(todo) {
      const response = await fetch(`${config.apiUrl}/todos`, { 
        method: 'POST',
        headers: {
          'Authorization': `${cognito.getIdToken()}`,
          // 'content-type': 'application/json'
        },
        body: JSON.stringify(todo)
      })
  
      if (!response.ok) {
        const message = `An error has occured: ${await response.text()}`;
        throw new Error(message);
      }

      // const data = await response.json();

      // return data;
    }

    static async updateTodo(todo) {
      const response = await fetch(`${config.apiUrl}/todos/${todo.id}`, { 
        method: 'PUT',
        headers: {
          'Authorization': `${cognito.getIdToken()}`,
          // 'content-type': 'application/json'
        },
        body: JSON.stringify(todo)
      })
  
      if (!response.ok) {
        const message = `An error has occured: ${await response.text()}`;
        throw new Error(message);
      }

      // const data = await response.json();

      // return data;
    }

    static async deleteTodo(id) {
      const response = await fetch(`${config.apiUrl}/todos/${id}`, { 
        method: 'DELETE',
        headers: {
          'Authorization': `${cognito.getIdToken()}`,
          // 'content-type': 'application/json'
        },
      })

      if (!response.ok) {
        const message = `An error has occured: ${await response.text()}`;
        throw new Error(message);
      }
    }
}
