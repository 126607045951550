import { FaRegTrashAlt, FaSquare, FaCheckSquare } from 'react-icons/fa'
import moment from 'moment';

const Todo = ({ todo, onDelete, onToggle }) => {
    return (
        <div key={todo.id} className={`grid grid-todo ${todo.completed ? 'strikethrough' : '' }`} onDoubleClick={() => onToggle(todo)} >
            <div>{ todo.completed ? <FaCheckSquare onClick={() => onToggle(todo)} /> : <FaSquare onClick={() => onToggle(todo)} />  }</div>
            <div>{todo.title}</div>
            <div>{moment(todo.when).format("Do MMM YY HH:mm")}</div>
            <div>
                <FaRegTrashAlt 
                    style={{ cursor: 'pointer' }} 
                    onClick={() => onDelete(todo.id)} 
                />
            </div>
        </div>
    )
}

// const Todo = ({ todo, onDelete, onToggle }) => {
//     return (
//         <div className={`todo ${todo.reminder ? 'reminder' : ''}`} onDoubleClick={() => onToggle(todo.id)} >
//             <h3>
//                 {todo.title}
//                 <FaTimes 
//                     style={{ color: 'red', cursor: 'pointer' }} 
//                     onClick={() => onDelete(todo.id)} 
//                 />
//             </h3>
//             <p>{todo.when}</p>
//         </div>
//     )
// }

export default Todo
